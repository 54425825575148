<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance the chemical reaction shown below. Be sure to include values of 1 for any species
        that only have 1 equivalent involved in the reaction (i.e. empty inputs DO NOT correspond to
        the value "1"). Use integers for ALL coefficients.
      </p>

      <p class="mb-2">
        <v-text-field
          v-model="inputs.metalOxide"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol1" />

        <v-text-field
          v-model="inputs.acidBase"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol2" />

        <v-text-field
          v-model="inputs.metalCompound"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol3" />

        <v-text-field
          v-model="inputs.h2o"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol4" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question360',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        metalOxide: null,
        acidBase: null,
        metalCompound: null,
        h2o: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1: // 1:2:1:1
          return {
            mol1Var: 'ZnO +',
            mol2Var: 'NaOH -> ',
            mol3Var: 'Na2ZnO2 +',
            mol4Var: 'H2O',
          };
        case 2: // 1:2:1:1
          return {
            mol1Var: 'Al2O3 +',
            mol2Var: 'NaOH -> ',
            mol3Var: 'NaAlO2 +',
            mol4Var: 'H2O',
          };
        case 3: // 2:6:2:3
          return {
            mol1Var: 'Al2O3 +',
            mol2Var: 'HCl -> ',
            mol3Var: 'AlCl3 +',
            mol4Var: 'H2O',
          };
        case 4: // 2:2:2:1
          return {
            mol1Var: 'SiO2 +',
            mol2Var: 'NaOH -> ',
            mol3Var: 'Na2SiO3 +',
            mol4Var: 'H2O',
          };
        default:
          return {
            mol1Var: 'ERROR +',
            mol2Var: 'ERROR -> ',
            mol3Var: 'ERROR +',
            mol4Var: 'ERROR',
          };
      }
    },
    mol1() {
      return this.versionData.mol1Var;
    },
    mol2() {
      return this.versionData.mol2Var;
    },
    mol3() {
      return this.versionData.mol3Var;
    },
    mol4() {
      return this.versionData.mol4Var;
    },
  },
};
</script>
